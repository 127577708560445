const predefinedStructure = {
  Dashboard: [
    "Dashboard Default View",
    "View all Events (Redirect)",
    "Virtual View",
  ],
  "Property Overview": ["Property Overview Default View", "Send Commands"],
  Statistics: ["Graph View", "Generate Report", "Table View"],
  Events: ["Events Default View", "Export Events"],
  Settings: [
    "Flow Configuration Default View",
    "Create New FC",
    "Edit FC",
    "Schedule Default View",
    "Create New Schedule",
    "Edit Schedule",
  ],
};

export default predefinedStructure;
