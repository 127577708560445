import { Table } from "antd";
import PropTypes from "prop-types";
import "./ReportsTable.scss";

const propTypes = {
  dataAsStringArr: PropTypes.array,
};

const defaultProps = {
  dataAsStringArr: [],
};

function ReportsTable({ dataAsStringArr }) {
  if (dataAsStringArr && dataAsStringArr.length > 0) {
    // Table column headers
    const headerKeys =
      dataAsStringArr && dataAsStringArr.length > 0
        ? Object.keys(dataAsStringArr[0])
        : [];
    const headers = headerKeys.map((h) => ({
      title: h,
      dataIndex: h,
      key: h,
      width: 200,
    }));

    // Actual table data
    const mappedDataToHeader = dataAsStringArr.map((row) =>
      headerKeys.map((header) => {
        return {
          [header]: row[header],
        };
      }),
    );
    const refinedDataForTable = mappedDataToHeader.map((result, key) => {
      return Object.assign({}, ...result, {
        key,
      });
    });

    return (
      <div className="wcr-table-container w-[72.7svw]">
        <Table
          columns={headers}
          dataSource={refinedDataForTable}
          pagination={false}
        />
      </div>
    );
  }

  return (
    <div className="wcr-table-container w-[72.7svw]">
      <Table
        columns={[]}
        dataSource={[]}
        scroll={{
          x: 500,
        }}
      />
    </div>
  );
}

ReportsTable.propTypes = propTypes;
ReportsTable.defaultProps = defaultProps;

export default ReportsTable;
