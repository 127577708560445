import { Button } from "antd";

import "./CmsFooter.scss";

function CmsFooter() {
  const currentYear = new Date().getFullYear();
  const currentAppVersion = process.env.REACT_APP_VERSION;
  const wasensLink = (
    <Button
      type="link"
      href="https://wasens.net/en/"
      target="_blank"
      rel="noopener noreferrer"
      size="small"
      style={{ padding: 0 }}
    >
      <span className="text-triple-blue">WASENS</span>
    </Button>
  );
  return (
    <div className="wasens-footer">
      <span className="mr-1">
        Copyright &copy; {currentYear} {wasensLink}
      </span>
      <span>All rights reserved.Version {currentAppVersion}</span>
    </div>
  );
}

export default CmsFooter;
