import { useEffect, useState } from "react";

import {
  DownloadOutlined,
  PrinterOutlined,
  ShareAltOutlined,
  FormOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { Select, Row, Col, DatePicker, Divider, Form, Tooltip } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import useWaterConsuptionFilters from "../../../api/hooks/useWaterConsuptionFilters";
import WaterDropIcon from "../../../assets/icons/WaterDropIcon";
import "./ReportsFilters.scss";
import DateConstants from "../../../constants/DateConstants";
import FilterReportDataModal from "../Modals/FilterReportDataModal";

const dateFormatsForBucketTypeYear = [
  {
    value: DateConstants.formats.fullYear_monthAbbreviation,
    label: DateConstants.formats.fullYear_monthAbbreviation_label,
  },
  {
    value: DateConstants.formats.fullYear_fullMonthName,
    label: DateConstants.formats.fullYear_fullMonthName_label,
  },
  {
    value: DateConstants.formats.monthAbbreviation_fullYear,
    label: DateConstants.formats.monthAbbreviation_fullYear_label,
  },
  {
    value: DateConstants.formats.fullMonthName_fullYear,
    label: DateConstants.formats.fullMonthName_fullYear_label,
  },
];

const dateFormatsForBucketTypeMonth = [
  {
    value: DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
    label:
      DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth_label,
  },
  {
    value: DateConstants.formats.fullYear_monthAbbreviation_dayOfMonth,
    label: DateConstants.formats.fullYear_monthAbbreviation_dayOfMonth_label,
  },
];

const dateFormatsForBucketTypeDay = [
  {
    value:
      DateConstants.formats.dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
    label:
      DateConstants.formats
        .dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label,
  },
  {
    value:
      DateConstants.formats
        .dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
    label:
      DateConstants.formats
        .dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label,
  },
];

function ReportsFilters({
  handleOnChangeFilterCallback,
  generateReportForm,
  onExportClick,
  onPrintClick,
}) {
  const [isReportDataModalVisible, setIsReportDataModalVisible] =
    useState(false);
  const [floorSelectDisabled, setFloorSelectDisabled] = useState(true);
  const [systemSelectDisabled, setSystemSelectDisabled] = useState(true);
  // comment below applies to the following three states: selectedPropertyId, selectedFloorId & selectedSystemId
  // using "" as default value, rather than null, due to errors in browser console when select options' value is null
  const [selectedPropertyId, setSelectedPropertyId] = useState("");
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [selectedSystemId, setSelectedSystemId] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateFormat, setSelectedDateFormat] = useState(
    DateConstants.formats.fullYear_monthAbbreviation,
  );
  const [selectedAggregationLevel, setSelectedAggregationLevel] =
    useState("Device");
  const [selectedBucketType, setSelectedBucketType] = useState("year");
  const [selectedIncludePreviousYear, setSelectedIncludePreviousYear] =
    useState(false);
  const [selectedUnitOfMeasurement, setSelectedUnitOfMeasurement] =
    useState("liters");
  const [propertyNameField, setPropertyNameField] = useState("Property");
  const [propertyAddressField, setPropertyAddressField] =
    useState("Property Address");
  const [floorNameField, setFloorNameField] = useState("Floor");
  const [systemNameField, setSystemNameField] = useState("System");
  const [deviceNameField, setDeviceNameField] = useState("Device");
  const [deviceSerialNumberField, setDeviceSerialNumberField] =
    useState("Device S/N");
  const [deviceLocationField, setDeviceLocationField] =
    useState("Device Location");
  const [deviceTypeField, setDeviceTypeField] = useState("Device Type");
  const [datePickerOption, setDatePickerOption] = useState("year");
  const [dateFormatOptions, setDateFormatOptions] = useState(
    dateFormatsForBucketTypeYear,
  );
  const [floorOptions, setFloorOptions] = useState([
    { value: "", label: "All Floors" },
  ]);
  const [systemOptions, setSystemOptions] = useState([
    { value: "", label: "All Systems" },
  ]);
  const propertyOptions = [{ value: "", label: "All Properties" }];

  // Hooks
  const { data: propertiesObject } = useWaterConsuptionFilters();
  // Configure the select dropdowns with values
  if (propertiesObject && propertiesObject.Properties) {
    propertiesObject.Properties.forEach((p) =>
      propertyOptions.push({ value: p.Id, label: p.Name }),
    );
  }

  // Effects
  useEffect(() => {
    if (
      selectedPropertyId !== "" &&
      propertiesObject &&
      propertiesObject.Properties
    ) {
      let property = null;
      const tempFloorOptions = [{ value: "", label: "All Floors" }];
      const tempSystemOptions = [{ value: "", label: "All Systems" }];
      propertiesObject.Properties.forEach((p) => {
        if (p.Id === selectedPropertyId) {
          property = p;
        }
      });

      if (property) {
        property.Floors?.forEach((f) => {
          tempFloorOptions.push({ value: f.Id, label: f.Name });

          if (f) {
            f?.Systems?.forEach((s) =>
              tempSystemOptions.push({ value: s.Id, label: s.Name }),
            );
          } else if (selectedFloorId === "") {
            property?.Floors?.forEach((floor) => {
              floor.Systems.map((fs) =>
                tempSystemOptions.push({ value: fs.Id, label: fs.Name }),
              );
            });
          }
        });
      }

      setFloorOptions(tempFloorOptions);
      setSystemOptions(tempSystemOptions);
    }
  }, [selectedPropertyId, selectedFloorId, propertiesObject]);

  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
    );
  });

  // Effects
  useEffect(() => {
    handleOnChangeFilterCallback(
      selectedPropertyId,
      selectedFloorId,
      selectedSystemId,
      selectedDate,
      selectedDateFormat,
      selectedAggregationLevel,
      selectedBucketType,
      selectedIncludePreviousYear,
      propertyNameField,
      propertyAddressField,
      floorNameField,
      systemNameField,
      deviceNameField,
      deviceSerialNumberField,
      deviceLocationField,
      deviceTypeField,
    );
  });

  const onChangeProperty = (value) => {
    if (!value) {
      setSelectedPropertyId("");
      setFloorSelectDisabled(true);
      setSystemSelectDisabled(true);
    } else {
      setSelectedPropertyId(value);
      setFloorSelectDisabled(false);
      setSystemSelectDisabled(false);
    }

    // on changing property filter option
    // set floor & system filter options to default
    setSelectedFloorId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
    setSelectedSystemId("");
  };

  const onChangeFloor = (value) => {
    if (value) {
      setSelectedFloorId(value);
    }

    // on changing floor filter option
    // set system filter options to default
    setSelectedSystemId(""); // using "" as default value, rather than null, due to errors in browser console when select options' value is null
  };

  const onChangeSystem = (value) => {
    if (value) {
      setSelectedSystemId(value);
    }
  };

  const onDateChange = (_, dateString) => {
    if (dateString) {
      setSelectedDate(dateString);
    }
  };

  const onChangeDateFormat = (value) => {
    if (value) {
      setSelectedDateFormat(value);
    } else {
      setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);
    }
  };

  const onChangeAggregationLevel = (value) => {
    if (value) {
      setSelectedAggregationLevel(value);
    } else {
      setSelectedAggregationLevel("Device");
    }
  };

  const onChangeBucketType = (value) => {
    if (value) {
      const previousBucketType = selectedBucketType;
      const goingLowerBucketType =
        (previousBucketType === "year" &&
          (value === "month" || value === "day")) ||
        (previousBucketType === "month" && value === "day"); // (year => month => day)
      setDatePickerOption(value);
      setSelectedBucketType(value);

      if (goingLowerBucketType) {
        // we want to update the date (timeframe selection selector) state
        // the logic is as follows:
        // 1. if current bucket type selection is year:
        //    a. if selectedDate is null - we set today's year
        //    b. if selectedDate has value - we set only the value's year
        // 2. if current bucket type selection is month:
        //    a. if selectedDate is null - we set today's year + today's month
        //    b. if selectedDate has value - we set the value's year + the value's month
        // 3. if current bucket type selection is day:
        //    a. if selectedDate is null - we set today's date
        //    b. if selectedDate has value - we set the value's year + the value's month + the value's day
        const newDate = `${new Date(
          selectedDate === null ? dayjs() : selectedDate,
        ).getFullYear()}-${dayjs().month() + 1}-${dayjs().date()}`; // ${dayjs().month() + 1} is not ideal, but javascript-based dayjs() library month values are zero indexed

        setSelectedDate(dayjs(newDate));

        // after updating the selectedDate' state
        // we want to update the actual date picker field in the UI
        generateReportForm.setFieldsValue({
          date: goingLowerBucketType ? dayjs(newDate) : dayjs(selectedDate),
        });
      }
      // the above logic is not necessary for switching to a higher level (day => week => month => year)

      // set date format options according to selected timeframe
      if (value === "day") {
        setDateFormatOptions(dateFormatsForBucketTypeDay);
        setSelectedDateFormat(
          DateConstants.formats
            .dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
        );

        // after updating the selectedDateFormat's state
        // we want to update the actual selector field in the UI
        generateReportForm.setFieldsValue({
          dateFormat:
            DateConstants.formats
              .dayOfMonth_monthAbbreviation_fullYear_hours_minutes,
        });
      } else if (value === "month") {
        setDateFormatOptions(dateFormatsForBucketTypeMonth);
        setSelectedDateFormat(
          DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
        );

        generateReportForm.setFieldsValue({
          dateFormat:
            DateConstants.formats.twoDigitsYear_monthAbbreviation_dayOfMonth,
        });
      } else {
        setDateFormatOptions(dateFormatsForBucketTypeYear);
        setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);

        generateReportForm.setFieldsValue({
          dateFormat: DateConstants.formats.fullYear_monthAbbreviation,
        });
      }
    } else {
      setSelectedBucketType("year");
      setDatePickerOption("year");

      setDateFormatOptions(dateFormatsForBucketTypeYear);
      setSelectedDateFormat(DateConstants.formats.fullYear_monthAbbreviation);

      generateReportForm.setFieldsValue({
        dateFormat: DateConstants.formats.fullYear_monthAbbreviation,
      });
    }
  };

  const onChangeIncludePreviousYear = (value) => {
    setSelectedIncludePreviousYear(value);
  };

  const onChangeUnitOfMeasurement = (value) => {
    setSelectedUnitOfMeasurement(value);
  };

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <div className="report-filters-header-container">
        <div className="wcr-report-filters-header-icon-container gap-1">
          <div className="text-triple-background uppercase text-[8px]">
            Consumption
          </div>
          <div className="w-12 h-12 border-[5px] border-triple-blue border-solid rounded-full flex items-center justify-center">
            <WaterDropIcon
              className="w-6 h-6 align-sub"
              strokeColor="#55787D"
            />
          </div>
        </div>
        <div className="reports-filters">
          <Row>
            <Col>
              <Row>
                <Select
                  size="small"
                  showSearch
                  placeholder="All Properties"
                  optionFilterProp="property"
                  onChange={onChangeProperty}
                  filterOption={filterSelect}
                  className="wcr-report-filter-selectors"
                  defaultValue=""
                  options={propertyOptions}
                />
              </Row>
              <Row>
                <div className="wcr-report-filters-buttons">
                  <div>Header</div>
                  <FormOutlined />
                </div>
              </Row>
            </Col>
            <Col className="flex items-center mx-2">
              <Divider
                className="py-7"
                style={{ borderColor: "#fff" }}
                type="vertical"
              />
            </Col>
            <Col>
              <Row className="mb-1">
                <div
                  role="presentation"
                  onClick={() => setIsReportDataModalVisible(true)}
                  className="wcr-report-filters-buttons"
                >
                  <div>Report Data</div>
                  <FormOutlined />
                </div>
              </Row>
              <Row>
                <div className="wcr-report-filters-buttons">
                  <div>Graphics</div>
                  <LineChartOutlined />
                </div>
              </Row>
            </Col>
            <Col className="flex items-center mx-2">
              <Divider
                className="py-7"
                style={{ borderColor: "#fff" }}
                type="vertical"
              />
            </Col>
            <Col className="flex items-center">
              <Row align="middle">
                <Form.Item
                  name="date"
                  rules={[
                    { required: true, message: "Date field is required." },
                  ]}
                >
                  <DatePicker
                    className="wcr-report-filters-timeframe-button"
                    inputReadOnly
                    placeholder="Select specific timeframe"
                    value={selectedDate}
                    onChange={(_, dateString) =>
                      onDateChange(_, dateString, "ReportDate")
                    }
                    disabledDate={(current) => {
                      if (current.isAfter(dayjs())) {
                        return true;
                      }

                      return false;
                    }}
                    picker={datePickerOption}
                  />
                </Form.Item>
              </Row>
            </Col>
            <Col className="flex items-center mx-2">
              <Divider
                className="py-7"
                style={{ borderColor: "#fff" }}
                type="vertical"
              />
            </Col>
            <Col className="flex items-center mr-2">
              <Row>
                <Select
                  size="small"
                  showSearch
                  placeholder="All Floors"
                  optionFilterProp="propertyFloor"
                  onChange={onChangeFloor}
                  filterOption={filterSelect}
                  className="wcr-report-filter-selectors"
                  disabled={floorSelectDisabled}
                  value={selectedFloorId}
                  options={floorOptions}
                />
              </Row>
            </Col>
            <Col className="flex items-center mr-2">
              <Row>
                <Select
                  size="small"
                  showSearch
                  placeholder="All Zones"
                  optionFilterProp="propertyZone"
                  className="wcr-report-filter-selectors"
                  disabled
                />
              </Row>
            </Col>
            <Col className="flex items-center mr-2">
              <Row>
                <Select
                  size="small"
                  showSearch
                  dropdownStyle={{ minWidth: "130px" }}
                  placeholder="All Systems"
                  optionFilterProp="propertySystem"
                  onChange={onChangeSystem}
                  filterOption={filterSelect}
                  className="wcr-report-filter-selectors"
                  disabled={systemSelectDisabled}
                  value={selectedSystemId}
                  options={systemOptions}
                />
              </Row>
            </Col>
            <Col className="flex items-center mr-2">
              <Row>
                <Select
                  size="small"
                  showSearch
                  placeholder="All Devices"
                  optionFilterProp="propertyDevice"
                  className="wcr-report-filter-selectors"
                  disabled
                />
              </Row>
            </Col>
          </Row>
        </div>
        <div className="items-center flex">
          <Col>
            <Row className="mb-2">
              <Tooltip title="Print Report" color="#06a5d3" key="print">
                <div
                  className="cursor-pointer text-triple-white hover:text-triple-blue"
                  role="presentation"
                  onClick={() => onPrintClick()}
                >
                  <PrinterOutlined className="text-18" />
                </div>
              </Tooltip>
            </Row>
            <Row className="mb-2">
              <Tooltip title="Download CSV File" color="#06a5d3" key="csv">
                <div
                  className="cursor-pointer text-triple-white hover:text-triple-blue"
                  role="presentation"
                  onClick={() => onExportClick()}
                >
                  <DownloadOutlined className="text-18" />
                </div>
              </Tooltip>
            </Row>
            <Row>
              <Tooltip title="Share Report" color="#06a5d3" key="share">
                <div className="cursor-pointer text-triple-white hover:text-triple-blue">
                  <ShareAltOutlined className="text-18" />
                </div>
              </Tooltip>
            </Row>
          </Col>
        </div>
      </div>
      <FilterReportDataModal
        open={isReportDataModalVisible}
        onClose={() => setIsReportDataModalVisible(false)}
        selectedAggregationLevel={selectedAggregationLevel}
        selectedUnitOfMeasurement={selectedUnitOfMeasurement}
        setSelectedUnitOfMeasurement={setSelectedUnitOfMeasurement}
        propertyNameField={propertyNameField}
        setPropertyNameField={setPropertyNameField}
        propertyAddressField={propertyAddressField}
        setPropertyAddressField={setPropertyAddressField}
        floorNameField={floorNameField}
        setFloorNameField={setFloorNameField}
        systemNameField={systemNameField}
        setSystemNameField={setSystemNameField}
        deviceNameField={deviceNameField}
        setDeviceNameField={setDeviceNameField}
        deviceSerialNumberField={deviceSerialNumberField}
        setDeviceSerialNumberField={setDeviceSerialNumberField}
        deviceLocationField={deviceLocationField}
        setDeviceLocationField={setDeviceLocationField}
        deviceTypeField={deviceTypeField}
        setDeviceTypeField={setDeviceTypeField}
        dateFormatOptions={dateFormatOptions}
        onChangeDateFormat={onChangeDateFormat}
        onChangeAggregationLevel={onChangeAggregationLevel}
        onChangeBucketType={onChangeBucketType}
        onChangeIncludePreviousYear={onChangeIncludePreviousYear}
        onChangeUnitOfMeasurement={onChangeUnitOfMeasurement}
      />
    </>
  );
}

ReportsFilters.defaultProps = {
  handleOnChangeFilterCallback: () => {},
  generateReportForm: {},
  onExportClick: () => {},
  onPrintClick: () => {},
};
ReportsFilters.propTypes = {
  handleOnChangeFilterCallback: PropTypes.func,
  generateReportForm: PropTypes.object,
  onExportClick: PropTypes.func,
  onPrintClick: PropTypes.func,
};

export default ReportsFilters;
