import { useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Skeleton, Pagination } from "antd";
import { useSearchParams } from "react-router-dom";

import useProperties from "../../../api/hooks/useProperties";
import usePropertiesCount from "../../../api/hooks/usePropertiesCount";
import useWindowSize from "../../../hooks/useWindowSize";
import SearchInput from "../../SearchInput/SearchInput";
import PropertyCard from "../PropertyCard/PropertyCard";

function PropertyList() {
  const window = useWindowSize();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const pageSize = 14;
  const [searchParams, setSearchParams] = useSearchParams();

  // get search params from URl and format them
  // as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;

  const { data: totalPropertiesCount } = usePropertiesCount({
    search: debouncedSearchQuery,
  });

  const { data: properties, status } = useProperties({
    pageSize,
    startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
    search: debouncedSearchQuery,
  });

  return (
    <div className="property-list">
      <div className="flex md:items-center mb-2 flex-col-reverse md:flex-row">
        <div className="flex-1 mt-6 md:mt-0 flex lg:items-center flex-col lg:flex-row">
          {window?.width > 768 && (
            <h1 className="page-title mb-0">Property Overview</h1>
          )}

          <div className="states lg:ml-2 xl:ml-4">
            <div>
              <span className="square square--blue">&nbsp;</span>
              <span>Neutral state</span>
            </div>
            <div>
              <span className="square square--orange">&nbsp;</span>
              <span>Negative state</span>
            </div>
          </div>
        </div>

        <SearchInput
          className="ml-auto w-full md:w-fit"
          placeholder="Search Properties"
          disabled={
            (!searchQuery && properties?.length === 0) || status === "loading"
          }
          value={searchQuery}
          onChange={(e) => {
            if (e.target.value === "") {
              setDebouncedSearchQuery("");
            }
            setSearchQuery(e.target.value);
            setSearchParams({ page: 0 });
          }}
          onSearch={() => setDebouncedSearchQuery(searchQuery)}
        />
      </div>

      {status === "loading" &&
        Array.from({ length: pageSize }, (value, index) => index).map((el) => (
          <Skeleton
            active
            paragraph={{
              rows: 3,
            }}
            key={el}
          />
        ))}

      {status === "success" && properties?.length === 0 && (
        <div className="p-6 text-white text-opacity-80">
          <FrownOutlined className="text-xl mr-2" />
          We couldn`t find any matches
        </div>
      )}

      {status === "success" && properties?.length > 0 && (
        <div className="sm:grid sm:grid-cols-2 lg:grid-cols-1 gap-x-3 lg:gap-x-0 mb-6">
          {properties
            ?.sort((firstElement, secondElement) =>
              firstElement.Name.localeCompare(secondElement.Name),
            )
            .map((property) => (
              <PropertyCard key={property.Id} property={property} />
            ))}
        </div>
      )}

      {status === "success" &&
        properties?.length > 0 &&
        totalPropertiesCount > pageSize && (
          <Pagination
            defaultCurrent={1}
            pageSize={pageSize}
            total={totalPropertiesCount}
            current={Number(searchParams.get("page")) || 1}
            showSizeChanger={false}
            size={window?.width <= 576 && "small"}
            onChange={(page) => {
              setSearchParams({ page });
            }}
          />
        )}
    </div>
  );
}

export default PropertyList;
