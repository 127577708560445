import { useEffect, useState } from "react";

import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import Search from "antd/es/input/Search";
import { useSearchParams } from "react-router-dom";

import useUsersManagementGetUsers from "../../../api/hooks/useUsersManagementGetUsers";
import useAuth from "../../../hooks/useAuth";
import useWindowSize from "../../../hooks/useWindowSize";
import Loader from "../../Loader/Loader";
import CreateUser from "./CreateUser";
import UsersListLicenses from "./UsersListLicenses/UsersListLicenses";
import UsersListTable from "./UsersListTable/UsersListTable";
import "./scss/UsersList.scss";

function UsersList() {
  const window = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [createFormReference, setFormReference] = useState();

  const { auth } = useAuth();
  const pageSize = auth?.rowsPerPage;
  // get search params from URl and format them as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;
  const {
    data: users,
    status,
    refetch: getUsersForManagement,
  } = useUsersManagementGetUsers(
    {
      pageSize,
      startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
      search: debouncedSearchQuery,
    },
    {
      enabled: pageSize > 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    getUsersForManagement();
  }, [debouncedSearchQuery, page, getUsersForManagement]);

  const onPageChange = (pagination) => {
    setSearchParams({ ...params, page: pagination.current });
  };

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const openCreateNewUserModal = () => {
    setIsCreateUserModalOpen(true);
  };

  const handleOnCancelCreateModal = () => {
    if (isFormDirty) {
      Modal.confirm({
        title: "You have unsaved changes.",
        content:
          "You can cancel to continue editing or leave and discard changes.",
        className: "alert-unsaved-changes",
        onOk() {
          setIsCreateUserModalOpen(false);
          setIsFormDirty(false);
          createFormReference.resetFields();
        },
        onCancel() {
          // Do Nothing
        },
        okButtonProps: {
          danger: true,
          className: "btn-leave",
        },
        okText: "Leave",
        cancelButtonProps: { className: "btn-cancel" },
      });
    } else {
      setIsCreateUserModalOpen(false);
      createFormReference.resetFields();
    }
  };

  return (
    <div className="content-wrapper overflow-y-auto">
      <div className="users-list flex h-full flex-col">
        <div className="flex items-center">
          {window?.width > 992 && (
            <h1 className="page-title mb-0">Users list</h1>
          )}

          <div className="ml-auto inline-flex items-center">
            <Search
              className="w-full md:w-fit mr-4"
              placeholder="Search Users"
              onSearch={onSearch}
              onChange={handleSearchChange}
              allowClear
              size="middle"
            />
            <Button
              size="middle"
              type="primary"
              className="ml-auto p-2"
              onClick={openCreateNewUserModal}
            >
              <PlusCircleOutlined />
              Create New User
            </Button>
          </div>
        </div>

        <div className="my-2">
          <UsersListLicenses />
        </div>

        {!pageSize && <Loader className="absolute-center" />}

        {(status === "loading" || users?.TotalCount > 0 || searchQuery) && (
          <>
            <UsersListTable
              data={users?.Result}
              status={status}
              totalCount={users?.TotalCount}
              onChange={onPageChange}
            />
            <Modal
              className="create-new-user-modal"
              maskClosable={false}
              footer={null}
              open={isCreateUserModalOpen}
              keyboard={false}
              onCancel={handleOnCancelCreateModal}
              destroyOnClose
              wrapClassName="custom-create-user-modal-content"
            >
              <CreateUser
                setIsFormDirty={setIsFormDirty}
                setFormReference={setFormReference}
                setIsCreateUserModalOpen={setIsCreateUserModalOpen}
                refetchUsersForManagement={getUsersForManagement}
              />
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}

export default UsersList;
