import PropTypes from "prop-types";

import CustomTable from "../../../CustomTable/CustomTable";
import "../scss/UsersListTable.scss";

const propertyTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
};

const defaultPropertyTypes = {
  data: [],
  status: "loading",
  pageSize: 3,
  totalCount: 0,
  onChange: () => {},
};

function UsersListTable({ data, status, pageSize, totalCount, onChange }) {
  const renderTitle = (className, id, value) => {
    return <span id={id}>{value}</span>;
  };

  const renderText = (value) => {
    return (
      <div>
        <span className="text-white text-opacity-80 font-light">{value}</span>
      </div>
    );
  };

  const tableColumns = [
    {
      title: renderTitle(null, "username-col", "User Name"),
      key: "username",
      render: (object) => {
        return renderText(object?.Username);
      },
      width: 450,
    },
    {
      title: renderTitle(null, null, "Email"),
      key: "user_email",
      render: (object) => {
        return renderText(object?.Email);
      },
      width: 200,
    },
    {
      title: renderTitle(null, null, "Mobile"),
      key: "user_phone",
      render: (object) => {
        return renderText(object?.PhoneNumber);
      },
      width: 150,
    },
    {
      title: renderTitle(null, null, "Permission"),
      key: "user_permission",
      render: (object) => {
        return renderText(object?.Permission);
      },
      width: 150,
    },
    {
      title: renderTitle(null, null, "License Key"),
      key: "user_licenseKey",
      render: (object) => {
        return renderText(object?.LicenseKey);
      },
      width: 250,
    },
    {
      title: renderTitle(null, null, "Purchase Key"),
      key: "user_purchaseKey",
      render: (object) => {
        return renderText(object?.PurchaseKey);
      },
      width: 250,
    },
    // {
    //   title: null,
    //   key: "actions",
    //   render: (object) => {
    //     return (
    //       <Button
    //         type="link"
    //         size="small"
    //         onClick={() => navigate(`edit-user/${object?.Id}`)}
    //       >
    //         <EditOutlined />
    //         Edit
    //       </Button>
    //     );
    //   },
    //   width: 100,
    // },
  ];

  return (
    <CustomTable
      className={`${!pageSize ? "opacity-0" : "opacity-100"}`}
      data={data}
      status={status}
      autoRows
      noPagination={!(totalCount > pageSize) || status === "loading"}
      tableColumns={tableColumns}
      totalCount={totalCount}
      onChange={onChange}
    />
  );
}

UsersListTable.propTypes = propertyTypes;
UsersListTable.defaultProps = defaultPropertyTypes;

export default UsersListTable;
