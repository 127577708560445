import { useState, useEffect, useCallback, useRef } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Col, Row, Form, notification } from "antd";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";

import "./WaterConsumptionReport.scss";
import "./WCRPrint.css";
import useWaterConsumptionReport from "../../../api/hooks/useWaterConsumptionReport";
import Chart from "../Charts/WCRManagementReportChart";
import ReportsFilters from "../Filters/ReportsFilters";
import ReportsTable from "../Tables/ReportsTable";

function WaterConsumptionReport() {
  const [generateReportForm] = Form.useForm();
  const initialFormValues = useRef({});
  const [propertyId, setPropertyId] = useState(null);
  const [floorId, setFloorId] = useState(null);
  const [systemId, setSystemId] = useState(null);
  const [reportDate, setReportDate] = useState(null);
  const [dateFormat, setDateFormat] = useState(null);
  const [aggregationLevel, setAggregationLevel] = useState(null);
  const [bucketType, setBucketType] = useState(null);
  const [includePreviousYear, setIncludePreviousYear] = useState(false);
  const [propertyNameField, setPropertyNameField] = useState(null);
  const [propertyAddressField, setPropertyAddressField] = useState(null);
  const [floorNameField, setFloorNameField] = useState(null);
  const [systemNameField, setSystemNameField] = useState(null);
  const [deviceNameField, setDeviceNameField] = useState(null);
  const [deviceSerialNumberField, setDeviceSerialNumberField] = useState(null);
  const [deviceLocationField, setDeviceLocationField] = useState(null);
  const [deviceTypeField, setDeviceTypeField] = useState(null);
  const [reportDataAsStringArr, setReportDataAsStringArr] = useState(null);
  const [rawReportCSVData, setRawReportCSVData] = useState(null);
  const [reportFileName, setReportFileName] = useState(null);

  const componentToPrintRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
  });

  const isValidDate = (d) => {
    if (d === null) {
      return false;
    }

    return d instanceof Date || !Number.isNaN(d);
  };

  useEffect(() => {
    if (isValidDate(reportDate)) {
      generateReportForm.submit();
    }
  }, [
    generateReportForm,
    propertyId,
    floorId,
    systemId,
    reportDate,
    dateFormat,
    aggregationLevel,
    bucketType,
    includePreviousYear,
    propertyNameField,
    propertyAddressField,
    floorNameField,
    systemNameField,
    deviceNameField,
    deviceSerialNumberField,
    deviceLocationField,
    deviceTypeField,
  ]);

  const handleOnChangeFilterCallback = (
    propertyId,
    floorId,
    systemId,
    reportDate,
    dateFormat,
    aggregationLevel,
    bucketType,
    includePreviousYear,
    propertyNameField,
    propertyAddressField,
    floorNameField,
    systemNameField,
    deviceNameField,
    deviceSerialNumberField,
    deviceLocationField,
    deviceTypeField,
  ) => {
    setPropertyId(propertyId);
    setFloorId(floorId);
    setSystemId(systemId);
    setReportDate(reportDate);
    setDateFormat(dateFormat);
    setAggregationLevel(aggregationLevel);
    setBucketType(bucketType);
    setIncludePreviousYear(includePreviousYear);
    setPropertyNameField(propertyNameField);
    setPropertyAddressField(propertyAddressField);
    setFloorNameField(floorNameField);
    setSystemNameField(systemNameField);
    setDeviceNameField(deviceNameField);
    setDeviceSerialNumberField(deviceSerialNumberField);
    setDeviceLocationField(deviceLocationField);
    setDeviceTypeField(deviceTypeField);
  };

  initialFormValues.current = {
    date: null,
    dateFormat: "yyyy MMM",
    aggregationLevel: "Device",
    bucketType: "year",
    includePreviousYear: false,
    unitOfMeasurement: "liters",
  };

  const handleFieldsChange = (changedFields) => {
    const fieldName = changedFields[0]?.name[0];
    const fieldValue = changedFields[0]?.value;
    if (fieldName === "date") {
      if (fieldValue === null || fieldValue === "") {
        generateReportForm.setFields([
          {
            name: "date",
            errors: ["Date field is required."],
          },
        ]);
      }
    }
  };

  const { refetch: getWaterConsumptionReport } = useWaterConsumptionReport(
    {
      filtersWithDeviceId: {
        deviceId: null,
        propertyId: propertyId === "" ? null : propertyId,
        floorId: floorId === "" ? null : floorId,
        systemId: systemId === "" ? null : systemId,
      },
      aggregationLevel,
      bucketType,
      date: new Date(reportDate),
      dateFormat,
      upToDate: false,
      includeBuckets: false,
      includeTotal: true,
      includePreviousYear,
      reportFileType: "CSV",
      fields: {
        PropertyName: propertyNameField,
        PropertyAddress: propertyAddressField,
        FloorName: floorNameField,
        SystemName: systemNameField,
        DeviceName: deviceNameField,
        DeviceSerialNumber: deviceSerialNumberField,
        DeviceLocation: deviceLocationField,
        DeviceType: deviceTypeField,
      },
    },
    { enabled: false },
  );

  const parseCSV = (csv, delimiter = ",") => {
    const rows = [];
    const lines = csv.split("\n");

    for (let j = 0; j < lines.length; j += 1) {
      const line = lines[j];

      // If line is null skip empty lines
      if (line && line.trim()) {
        const row = [];
        let insideQuotes = false;
        let value = "";

        for (let i = 0; i < line.length; i += 1) {
          const char = line[i];

          if (char === '"') {
            insideQuotes = !insideQuotes;
          } else if (char === delimiter && !insideQuotes) {
            // If we encounter a delimiter outside of quotes, we add the value to the row
            row.push(value.trim());
            value = "";
            // Reset the value for the next field
          } else {
            // Add the current character to the value
            value += char;
          }
        }

        // Add the last value to the row
        if (value) {
          row.push(value.trim());
        }

        // Add the row to the results
        rows.push(row);
      }
    }
    return rows;
  };

  const handleReportSubmitCallback = useCallback(() => {
    getWaterConsumptionReport().then((response) => {
      if (response && response.isSuccess) {
        const responseReportFileName = response.data.FileName;
        setRawReportCSVData(response.data.Value);
        setReportFileName(responseReportFileName);

        // Parsing the raw CSV string to table format
        const parsedData = parseCSV(response.data.Value);

        // Extract headers and rows
        const headers = parsedData[0];
        const tableData = parsedData.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header.trim()] = row[index].trim();
          });
          return obj;
        });

        setReportDataAsStringArr(tableData);
      } else {
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              Something went wrong!
            </span>
          ),
          description: (
            <span className="uppercase tracking-widest">
              {response && response.error && response.error.response
                ? response.error.response.data[0]
                : ""}
            </span>
          ),
          duration: 0,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });

        // Clear report data if no records are found from back-end
        setReportDataAsStringArr(null);
        setRawReportCSVData(null);
        setReportFileName(null);
      }
    });
  }, [getWaterConsumptionReport]);

  const handleExportSubmitCallback = useCallback(() => {
    if (reportDataAsStringArr && reportDataAsStringArr.length > 0) {
      const timestamp = dayjs().format("YYYY-MM-DD HH:mm:ss");
      const url = window.URL.createObjectURL(new Blob([rawReportCSVData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${reportFileName}-${timestamp}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      notification.error({
        message: (
          <span className="uppercase tracking-widest">
            Please generate report.
          </span>
        ),
        description: (
          <span className="uppercase tracking-widest">
            No report data to export. Please generate report first.
          </span>
        ),
        duration: 0,
        placement: "topRight",
        icon: <FrownOutlined className="text-triple-red" />,
      });
    }
  }, [reportDataAsStringArr, rawReportCSVData, reportFileName]);

  return (
    <Row ref={componentToPrintRef} className="wcr-main-container">
      <Row>
        <Form
          form={generateReportForm}
          name="generate-report-form"
          layout="vertical"
          className="w-full"
          onFinish={handleReportSubmitCallback}
          onFieldsChange={handleFieldsChange}
          initialValues={initialFormValues.current}
        >
          <Col xs={24}>
            <ReportsFilters
              handleOnChangeFilterCallback={handleOnChangeFilterCallback}
              generateReportForm={generateReportForm}
              onExportClick={handleExportSubmitCallback}
              onPrintClick={handlePrint}
            />
          </Col>
        </Form>
      </Row>
      <Row gutter={[16, 16]} className="pt-2">
        <Col xs={24}>
          <ReportsTable dataAsStringArr={reportDataAsStringArr} />
        </Col>
        <Col xs={24}>
          <Chart
            date={reportDate}
            propertyId={propertyId}
            floorId={floorId}
            systemId={systemId}
            showPreviousWaterConsumptionData={includePreviousYear}
            setDate={setReportDate}
          />
        </Col>
      </Row>
      <Row className="pt-12">
        <Col xs={24}>
          <div className="flex items-center rounded-md w-[72.7svw] h-[12svh] bg-triple-blue">
            <div className="flex items-center rounded-md m-2.5 w-full h-[10.2svh] bg-triple-sidebar-new/50">
              <div className="flex items-center text-center font-extralight text-triple-white/80 ml-3 rounded-md w-[75px] h-[75px] border-[1px] border-dashed border-triple-white">
                Logo Upload
              </div>
              <div className="ml-16 w-[40%] h-[80%] flex flex-col items-start gap-1">
                <div className="ml-3 rounded-sm w-full h-[2svh] bg-triple-white/80" />
                <div className="ml-3 rounded-sm w-full h-[2svh] bg-triple-white/80" />
                <div className="ml-3 rounded-sm w-full h-[2svh] bg-triple-white/80" />
                <div className="ml-3 rounded-sm w-full h-[2svh] bg-triple-white/80" />
              </div>
              <div className="ml-8 w-[40%] h-[80%] flex flex-col items-start gap-1">
                <div className="ml-3 rounded-sm w-full h-[2svh] bg-triple-white/80" />
                <div className="ml-3 rounded-sm w-full h-[2svh] bg-triple-white/80" />
                <div className="ml-3 rounded-sm w-full h-[2svh] bg-triple-white/80" />
                <div className="ml-3 rounded-sm w-full h-[2svh] bg-triple-white/80" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Row>
  );
}

export default WaterConsumptionReport;
