import React from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Checkbox, Divider } from "antd";
import PropTypes from "prop-types";

import predefinedStructure from "./UsersPermissionsDataStructure";

const parentDivClassName = "inline-flex w-full mb-6";
const divClass =
  "border border-solid rounded p-2 mt-2 border-triple-blue flex-grow";
const textDivClass = "font-light text-triple-white uppercase tracking-widest";

function UsersPermissionsList({ createUserFormRef, licensesData }) {
  const { LicenseValue } = createUserFormRef.getFieldsValue();
  const { User, Operator } = licensesData ?? {};
  const lookupMap = {};
  const data = {
    Result: [
      {
        UserLevelName: User?.find((_) => _)?.UserLevelName,
        UserLevelFeatures: User?.find((_) => _)?.UserLevelFeatures,
      },
      {
        UserLevelName: Operator?.find((_) => _)?.UserLevelName,
        UserLevelFeatures: Operator?.find((_) => _)?.UserLevelFeatures,
      },
    ],
  };
  data.Result.forEach((result) => {
    const userLevelFeatures = result.UserLevelFeatures || [];
    const userLevelName = result.UserLevelName;

    userLevelFeatures.forEach((userLevelFeature) => {
      if (!lookupMap[userLevelFeature.FeatureArea]) {
        lookupMap[userLevelFeature.FeatureArea] = {};
      }

      if (
        !lookupMap[userLevelFeature.FeatureArea][userLevelFeature.FeatureName]
      ) {
        lookupMap[userLevelFeature.FeatureArea][userLevelFeature.FeatureName] =
          {};
      }

      lookupMap[userLevelFeature.FeatureArea][userLevelFeature.FeatureName][
        userLevelName
      ] = true;
    });
  });

  const shouldHighlightAreaName = (area, userLevelName) => {
    return predefinedStructure[area].some(
      (featureName) => lookupMap[area]?.[featureName]?.[userLevelName],
    );
  };

  const shouldHighlightAreaChildName = (area, featureName, userLevelName) => {
    return lookupMap[area]?.[featureName]?.[userLevelName];
  };
  return (
    <div className={parentDivClassName}>
      <div className={divClass}>
        <span className={textDivClass}>permissions</span>
        <table className="mt-3">
          <tbody>
            {Object.keys(predefinedStructure).map((area) => (
              <React.Fragment key={area}>
                <tr
                  style={{
                    color: shouldHighlightAreaName(area, LicenseValue)
                      ? "#06a5d3"
                      : "#999898",
                  }}
                >
                  <td>
                    <span className="font-normal">{area}</span>
                    <Divider className="m-0" />
                  </td>
                </tr>
                {predefinedStructure[area].map((featureName) => (
                  <tr
                    key={featureName}
                    style={{
                      color: shouldHighlightAreaChildName(
                        area,
                        featureName,
                        LicenseValue,
                      )
                        ? "#f6f6f6"
                        : "#999898",
                    }}
                  >
                    <td>
                      {featureName}
                      <Divider className="m-0" />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className={`${divClass} mx-1 text-center`}>
        <span className={`${textDivClass} mr-2`}>Operator</span>
        <Checkbox checked={LicenseValue === "Operator"} />
        <table className="mt-3 w-full">
          <tbody>
            {Object.keys(predefinedStructure).map((area) => (
              <React.Fragment key={area}>
                <tr>
                  <td>
                    <span className="font-normal text-triple-background">
                      Area
                    </span>
                    <Divider className="m-0" />
                  </td>
                </tr>
                {predefinedStructure[area].map((featureName) => (
                  <tr key={featureName}>
                    <td className="text-center align-middle">
                      {lookupMap[area]?.[featureName]?.Operator ? (
                        <CheckOutlined
                          className={
                            LicenseValue === "Operator"
                              ? "text-triple-blue"
                              : ""
                          }
                        />
                      ) : (
                        <CloseOutlined />
                      )}
                      <Divider className="m-0" />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className={`${divClass} text-center`}>
        <span className={`${textDivClass} mr-2`}>User</span>
        <Checkbox checked={LicenseValue === "User"} />
        <table className="mt-3 w-full">
          <tbody>
            {Object.keys(predefinedStructure).map((area) => (
              <React.Fragment key={area}>
                <tr>
                  <td>
                    <span className="font-normal text-triple-background">
                      Area
                    </span>
                    <Divider className="m-0" />
                  </td>
                </tr>
                {predefinedStructure[area].map((featureName) => (
                  <tr key={featureName}>
                    <td>
                      {lookupMap[area]?.[featureName]?.User ? (
                        <CheckOutlined
                          className={
                            LicenseValue === "User" ? "text-triple-blue" : ""
                          }
                        />
                      ) : (
                        <CloseOutlined />
                      )}
                      <Divider className="m-0" />
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

UsersPermissionsList.propTypes = {
  createUserFormRef: PropTypes.object,
  licensesData: PropTypes.object,
};

UsersPermissionsList.defaultProps = {
  createUserFormRef: {},
  licensesData: {},
};
export default UsersPermissionsList;
