import {
  FlowManagementIcon,
  PropertyPreferencesIcon,
  ScheduleManagementIcon,
} from "../../../assets/icons/preferences-tiles-icons";
import useWindowSize from "../../../hooks/useWindowSize";
import BackButton from "../../Shared/BackButton";
import MenuTile from "../../Shared/MenuTile";

function PropertyPreferencesMenuPage() {
  const window = useWindowSize();

  return (
    <>
      <div className="flex flex-col">
        <BackButton
          redirect={{ to: "/preferences" }}
          confirmation={false}
          className="self-start uppercase tracking-widest mr-4"
        />
        {window?.width > 768 && (
          <h1 className="page-title mb-0 text-center sm:text-left !text-triple-blue">
            Property Management
          </h1>
        )}

        {window?.width <= 768 && (
          <h4 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
            Property Management
          </h4>
        )}
      </div>
      <div className="mt-4 flex md:flex-row flex-col md:items-start items-center gap-5">
        <MenuTile
          title="Property"
          icon={<PropertyPreferencesIcon />}
          navigateTo="/preferences/property-management/properties"
        />
        <MenuTile
          title="Flow"
          icon={<FlowManagementIcon />}
          navigateTo="/settings/flow-configuration"
        />
        <MenuTile
          title="Schedule"
          icon={<ScheduleManagementIcon />}
          navigateTo="/settings/schedule"
        />
      </div>
    </>
  );
}
export default PropertyPreferencesMenuPage;
