import dayjs from "dayjs";
import PropTypes from "prop-types";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import DateConstants from "../../../constants/DateConstants";
import {
  getMaxWaterConsumptionValue,
  roundToNearest,
} from "../../../helpers/calculateChartYAxisUpperBound";
import useProfileSettings from "../../../hooks/useProfileSettings";
import StatisticsCustomTooltip from "./StatisticsCustomTooltip";
import YAxisLabel from "./StatisticsYAxisLabel";

function StatisticsAnnualChart({
  waterConsumptionData,
  previousWaterConsumptionData,
  setSelectedMonth,
  showPreviousWaterConsumptionData,
  setDate,
  date,
}) {
  const { userSettings } = useProfileSettings();
  const handleBarClick = (selectedItem) => {
    const month = waterConsumptionData.StatisticsData.find(
      (item) => item.Month === selectedItem.Key,
    );
    setSelectedMonth(month);
    setDate({
      MM: DateConstants.months[month.MonthAsInt - 1],
      m: month.MonthAsInt - 1,
      d: dayjs().$D,
      year: date.year,
    });
  };

  const chartData = showPreviousWaterConsumptionData
    ? waterConsumptionData.StatisticsData?.map((item, index) => ({
        Key: item.Month,
        PreviousValue:
          previousWaterConsumptionData?.StatisticsData &&
          previousWaterConsumptionData?.StatisticsData.length > 0
            ? previousWaterConsumptionData.StatisticsData[index]
                .WaterConsumption
            : 0,
        Value: item.WaterConsumption,
      }))
    : waterConsumptionData?.StatisticsData?.map((item) => ({
        Key: item.Month,
        Value: item.WaterConsumption,
      }));

  const maxWaterConsumption = getMaxWaterConsumptionValue(
    waterConsumptionData?.StatisticsData,
  );

  let maxYAxisValue = maxWaterConsumption;

  if (showPreviousWaterConsumptionData) {
    const maxPreviousWaterConsumption = getMaxWaterConsumptionValue(
      previousWaterConsumptionData?.StatisticsData,
    );

    maxYAxisValue = Math.max(maxWaterConsumption, maxPreviousWaterConsumption);
  }

  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      className="bg-triple-white rounded-b-xl pl-1"
    >
      <BarChart data={chartData}>
        <CartesianGrid strokeDasharray="1 0" vertical={false} />
        <XAxis dataKey="Key" />
        <YAxis name="Consumption" domain={[0, roundToNearest(maxYAxisValue)]}>
          <Label content={YAxisLabel(userSettings.waterUnit)} />
        </YAxis>
        <Tooltip
          labelClassName="text-triple-sidebar"
          content={
            <StatisticsCustomTooltip
              year={waterConsumptionData?.Year}
              payload={waterConsumptionData?.StatisticsData}
              active
              chartType="Annual"
              waterUnit={userSettings.waterUnit}
            />
          }
        />

        <Legend verticalAlign="top" align="right" height={40} />

        {showPreviousWaterConsumptionData && (
          <Bar
            dataKey="PreviousValue"
            name={previousWaterConsumptionData?.Year}
            fill="#5cd6ec"
            barSize={15}
            minPointSize={5}
            onClick={handleBarClick}
            style={{ cursor: "pointer" }}
          />
        )}

        <Bar
          dataKey="Value"
          name={waterConsumptionData?.Year}
          fill="#03a5d2"
          barSize={15}
          minPointSize={5}
          onClick={handleBarClick}
          style={{ cursor: "pointer" }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

StatisticsAnnualChart.defaultProps = {
  waterConsumptionData: {},
  previousWaterConsumptionData: {},
  setSelectedMonth: () => {},
  showPreviousWaterConsumptionData: true,
  setDate: () => {},
  date: {},
};

StatisticsAnnualChart.propTypes = {
  waterConsumptionData: PropTypes.object,
  previousWaterConsumptionData: PropTypes.object,
  setSelectedMonth: PropTypes.func,
  showPreviousWaterConsumptionData: PropTypes.bool,
  setDate: PropTypes.func,
  date: PropTypes.object,
};

export default StatisticsAnnualChart;
